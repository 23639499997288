<script>
  import ArrowRight from "../../svg/ArrowRight.svelte";
  import ArrowLeft from "../../svg/ArrowLeft.svelte";
  import { onMount } from "svelte";
  import wrapApp from "../../scripts/mamasara";

  export let view;
  let onClick;

  if (view === "setup") {
    onClick = "/name";
  } else if (view === "name") {
    onClick = "/download";
  } else {
    onClick = "/setup";
  }

  onMount(async () => {
    //wrapApp.validateStepOne;
  });
</script>

<div class="row cont-bottom-base terms">
  <div class="col-12 h-auto p-4">
    {#if view === "setup"}
      <div class="row m-0">
        <div class="col cont-terms">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              id="term"
              type="checkbox"
              name="termsconditions"
              required />
            <label class="form-check-label" for="term">
              I have read and accept the terms and conditions. <a
                href="#terms"
                data-toggle="modal"
                data-target="#termsModal">Review here T&C.</a
              ></label>
          </div>
        </div>
      </div>
    {/if}
    <div class="row">
      <div class="d-none d-lg-block col align-self-center">
        <p class="m-0">VERSION <b>1.0.3</b></p>
      </div>
      <div class="col align-self-center text-center">
        <p class="m-0">
          All Right Reserved © <a href="/">Mamasara</a>
        </p>
      </div>
      <div class="col align-self-center text-right">
        <a type="button" class="text-center button-green" href={onClick}>
          {#if view != "download"}
            <span>NEXT</span><ArrowRight />
          {/if}
          {#if view === "download"}
            <ArrowLeft /><span>Back</span>
          {/if}
        </a>
      </div>
    </div>
  </div>
</div>
