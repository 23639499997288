const resultData = {
    downloadUri: "https://eclipse.c3sl.ufpr.br/oomph/epp/2021-06/R/eclipse-inst-jre-linux64.tar.gz",
    totalBuilds: "1000",
    savings: {
        total: "200",
        details: [
            {
                concept: "Lorem Ipsum",
                amount: "20"
            },
            {
                concept: "Lorem Ipsum",
                amount: "30"
            },
            {
                concept: "Lorem Ipsum",
                amount: "40"
            },
            {
                concept: "Lorem Ipsum",
                amount: "50"
            },
            {
                concept: "Lorem Ipsum",
                amount: "60"
            },
            {
                concept: "Lorem Ipsum",
                amount: "70"
            },
            {
                concept: "Lorem Ipsum",
                amount: "80"
            },
            {
                concept: "Lorem Ipsum",
                amount: "90"
            },
            {
                concept: "Lorem Ipsum",
                amount: "120"
            },
        ]
    }
}

export default resultData;